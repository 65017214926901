

.title-article {

    margin-top : 5rem;
    color: black;
    font-size: 35px;
}


.table-article {
    color : black;
    width: 90%;
    text-align: center;
    font-size: 20px;
}

.thead-article {
    width: 100%;
    height: 5rem;
    font-weight:550;
}

.link-article {
    
    
    color : black
    
}

.link-article svg {
    font-size: 1.6rem;
    color : var(--secondary-pink);
    font-weight: bold;
}